import {AccountEmail} from '@core/bo/account-email.model';
import {Organization} from '@core/bo/organization.model';
import {AccountStatus} from '@core/bo/account-status.model';
import {Location} from '@core/bo/location.model';

export class Authority {
    role: string;
    title: string;

    constructor(role?: string) {
        this.role = role;
    }
}

export class AccountBase {
    static STATUS_ACTIVE = [1, 4, 8];
    static STATUS_INACTIVE = [2];
    /*static STATUS_MAP = {
        1: AccountBase.STATUS_ACTIVE,
        2: AccountBase.STATUS_INACTIVE
    }
    static LEVEL_1 = ObjectCategory.newInstance('Nhân viên', 1, null, null);
    static LEVEL_2 = ObjectCategory.newInstance('Mentor', 2, null, null);
    static LEVEL_3 = ObjectCategory.newInstance('Team Leader', 3, null, null);
    static LEVEL_4 = ObjectCategory.newInstance('Giám đốc', 4, null, null);
    static LEVEL_5 = ObjectCategory.newInstance('Chủ tịch', 5, null, null);
    static LEVEL_MAP = {
        1: AccountBase.LEVEL_1,
        2: AccountBase.LEVEL_2,
        3: AccountBase.LEVEL_3,
        4: AccountBase.LEVEL_4,
        5: AccountBase.LEVEL_5
    }*/
    id: number;
    orgId: number;
    password: string;
    passwordSet: string;
    authorities: Authority[];
    emailPersonal: string;
    username: string;
    firstname: string;
    lastname: string;
    fullname: string;
    placeOfBirth: string;
    placeOfResidence: string;
    ethnic: string;
    religion: string;
    profilePath: string;
    identityCode: string;
    identityPhoto1: string;
    identityPhoto2: string;
    identityIssuer: string;
    identityDate: Date;
    taxPersonal: string;
    bankCode: string;
    bankNumber: string;
    birthday: Date;
    birthdayOfYear: string;
    gender: number;
    mobile: string;
    email: string;
    status: number;
    statusEndDate: Date;
    isTrash: boolean;
    isUpdated: boolean;
    code: number;
    oldCode: string;
    hiringDate: Date;
    suspendedDate: Date;
    level: number;
    countryMobileId: number;
    countryId: number;
    leaderId: number;
    profession: number;
    isSocialInsurance: boolean;
    socialInsuranceOrg: string;
    socialInsuranceCode: string;
    socialInsuranceLevel: number;
    title: string;
    orgIdList: number[];
    orgIdGrantList: number[];
    createdBy: number;
    createdDate: Date;
    modifiedBy: number;
    modifiedDate: Date;
    locationId: number;
    employeeType: string;
    workhour: string;
    workday: string;
}

export class Account extends AccountBase {
    static AUTHORITY_MAP = {
        USER: 'Người dùng cơ bản',
        USER_ALL: 'Kiểm tra trùng lặp',
        ACCOUNT: 'Quản lý người dùng',
        ACCOUNT_ID: 'Cập nhật định danh',
        ORGANIZATION: 'Quản lý đơn vị',
        APPROVE: 'Đồng bộ thay đổi',
        ASSIGN_ROLE: 'Gán quyền người dùng',
        CHECK_INOUT_ADMIN: 'Check In/Out Admin',
        CHECK_INOUT: 'Check In/Out',
        CODE2FA: 'Lấy mã xác minh',
        ROOT: 'Quyền tối cao'
    };
    static PARTNER_ID = 92;
    static PARTNER_CODE = '*1*' + Account.PARTNER_ID + '*';
    isLogged = false;
    emailSet: AccountEmail[];
    statusSet: AccountStatus[];
    oldPassword: string;
    idToken: string;
    org: Organization;
    leader: Account;
    isRoot = false;
    keyword: string;
    sortBy = 'createdDate';
    ascending = false;
    allChildren = true;
    roleCheck = false;
    // country: Country;
    countryIdList: number[];
    professionChecked: number[];
    employeeTypeChecked: string[];
    workhourChecked: string[];
    workdayChecked: string[];
    endDateRange: string;
}
